/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';
import titleImg from 'static/company/title_image.png';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';
import { useState, useEffect } from 'react';

const Title = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const TitleStyle = css`
    margin-top: 120px;
    padding: 0px 60px 180px;
    .box {
      border-radius: 48px;

      background: radial-gradient(circle at 50% 450%, #fff, #111927);
      padding-top: 120px;
      .container {
        width: 1352px;
        margin: 0 auto;
        overflow: hidden;
        > .title {
          font-size: 80px;
          font-style: normal;
          font-weight: 800;
          line-height: 130%;
          text-align: center;
          color: #fff;
          span {
            background: linear-gradient(90deg, #dcf2ff 0.24%, #9ed7fa 100.24%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        > .subTitle {
          color: #fff;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 45px;
          margin-top: 24px;
          text-align: center;
        }
        > .buttonContainer {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          .downloadButton {
            margin-top: 32px;
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            font-size: 25px;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #4c6c82;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
              .down-img {
                position: relative;
                animation: moveDown 0.6s ease-in-out infinite alternate;
              }
            }
            @keyframes moveDown {
              0% {
                transform: translateY(-3px);
              }
              100% {
                transform: translateY(3px);
              }
            }
          }
          .welfareButton {
            margin-left: 24px;
            margin-top: 32px;
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            /* margin: 51px auto 0px auto; */
            font-size: 25px;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #72aed3;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
        }
        > .img {
          position: relative;
          margin-top: 64px;
          width: 1104px;
          transform: translateY(10px);
          margin: 0 auto;
          img {
            width: 100%;
          }
        }
      }
    }
    ${mq.mobile} {
      margin-top: 0;
      padding: 48px 18px 100px 18px;
      .box {
        border-radius: 24px;
        padding-top: 80px;
        .container {
          width: 100%;
          padding: 0px 20px 0px 20px;
          > .title {
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 56px;
          }
          > .subTitle {
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
          }
          > .buttonContainer {
            flex-direction: column-reverse;
            align-items: center;
            .downloadButton,
            .welfareButton {
              width: 175px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              padding: 8px 8px 8px 20px;
              margin-left: 0;
              margin-top: 0;
              .arrow {
                width: 30px;
                height: 30px;
              }
            }
            .downloadButton {
              margin-top: 12px;
            }
          }
          > .img {
            position: relative;
            /* margin-top: 64px; */
            width: 277px;
            transform: translateY(10px);
            margin: 40px auto 0;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={TitleStyle}>
      <motion.div
        animate={{
          opacity: [0.1, 0.3, 0.5, 0.7, 1],
          transition: {
            duration: 1.5,
          },
        }}
        className="box"
      >
        <div className="container">
          <h2 className="title">
            <span>기업</span>
          </h2>
          <div className="subTitle">
            쇼핑 서비스를 제공하는
            <br />
            가장 쉬운 시작, 몰파이 비즈니스
          </div>
          <div className="buttonContainer">
            <a href="/inquiry" target="_blank" rel="noopener noreferrer">
              <button className="welfareButton">
                쇼핑몰 도입 문의
                <div className="arrow">
                  <ArrowRight className="arrow-img" />
                </div>
              </button>
            </a>
          </div>
          <div className="img">
            <img
              src={titleImg}
              alt="도입 · 운영 걱정은 줄이고 만족도는 높이는 몰파이 복지몰 | 이미지"
              title="도입 · 운영 걱정은 줄이고 만족도는 높이는 몰파이 복지몰"
            />
          </div>
        </div>{' '}
      </motion.div>
    </div>
  );
};

export default Title;
