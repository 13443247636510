import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Title from 'components/welfare/Title';
import Section1 from 'components/welfare/Section1';
import Section2 from 'components/welfare/Section2';
import Section3 from 'components/welfare/Section3';
import Section4 from 'components/welfare/Section4';
import Section5 from 'components/welfare/Section5';
import React from 'react';
import Bottom from 'components/welfare/Bottom';

const Welfare = () => {
  return (
    <Layout theme="white">
      <Title />
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
      <Section4 />
      <Section5 />
      <Bottom />
    </Layout>
  );
};

export default Welfare;
