/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as Close } from 'static/user/mypage/close.svg';
import React from 'react';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  onDontShowAgain: () => void;
}

const NoticePopup = ({ open, onClose, onDontShowAgain }: PopupProps) => {
  const { mq } = useTheme();

  const noticePopupStyle = css`
    position: fixed;
    z-index: 100;
    top: 120px;
    left: 60px;
    background-color: #fff;
    width: 560px;
    box-shadow: 10px 12px 30px 10px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    .header {
      height: 52px;
      background: #6079ff;
      border-radius: 10px 10px 0px 0px;
    }
    .wrap {
      padding: 40px 30px 10px 30px;
      border-radius: 10px;
      .title {
        padding-bottom: 30px;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        border-bottom: 1px solid #ebebeb;
      }
      .content {
        padding: 30px 0px;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 300;
        word-break: keep-all;
        max-height: calc(100vh - 400px);
        overflow-y: auto;
        span.red {
          color: #ff2121;
          font-weight: 300;
        }
        span {
          font-weight: 500;
        }
        span.small {
          font-weight: 300;
          font-size: 14px;
        }
        strong {
          font-weight: 600;
        }
      }
      .close-button {
        text-align: center;
        button {
          width: 280px;
          padding: 12px 0px;
          font-weight: 700;
          color: #ffffff;
          background: #6079ff;
          border: 0;
          border-radius: 4px;
        }
      }
    }
    .more {
      font-size: 13px;
      text-align: right;
      text-decoration: underline;
      padding: 20px 20px;
      cursor: pointer;
      span:hover {
        color: #6079ff;
        text-decoration-color: #6079ff;
      }
    }

    ${mq.mobile} {
      width: 80%;
      max-width: 480px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .header {
        height: 28px;
      }
      .wrap {
        padding: 30px 20px 0px 20px;
        .title {
          font-size: 16px;
          padding-bottom: 22px;
        }
        .content {
          padding: 24px 0px 12px;
          font-size: 14px;
        }
        .close-button {
          padding-top: 12px;
          button {
            width: 148px;
          }
        }
      }
      .more {
        font-size: 12px;
        padding: 16px;
      }
    }
  `;

  return (
    <>
      {open && (
        <div css={noticePopupStyle}>
          <div className="header"></div>
          <div className="wrap">
            <div className="title">몰파이 사칭 사이트 주의 안내</div>
            <div className="content">
              안녕하세요 몰파이에서 안내드립니다. <br /> <br /> 최근 몰파이를
              사칭하는 사이트로 피해 사례가 있어 주의 안내드립니다. <br />
              <br /> 사칭 사이트는{' '}
              <span className="red">mall-pie.com/shop</span> 이며, 당사의 서비스
              로고를 무단으로 사용하고 있습니다.
              <br />
              <br /> 위 사칭 사이트로 인해 개인정보 유출 및 금전적 피해가 발생할
              수 있으니 각별히 주의 부탁드립니다.
              <br />
              <br />{' '}
              <span>
                몰파이 공식 주소는 mallpie.kr 이며, 다른 주소를 사용하지
                않습니다.
              </span>
              <br />
              해당 주소 외 몰파이 사칭으로 의심되는 사례를 발견한 경우, 아래
              고객센터로 문의주시기 바랍니다.
              <br />
              <br />
              <span className="small">
                <strong>고객센터 전화</strong> ㅣ 1522-2856 (평일, 10:00-18:00)
              </span>
              <br />
              <span className="small">
                <strong>고객센터 이메일</strong>ㅣ support@genieworks.net
              </span>
            </div>
            <div className="close-button" onClick={onClose}>
              <button>확인</button>
            </div>
          </div>
          <div className="more" onClick={onDontShowAgain}>
            <span>일주일간 보지 않기</span>
          </div>
        </div>
      )}
    </>
  );
};

export default NoticePopup;
